.p-inputswitch{
    height: 24px !important;
}

.form-group-wrapper {
    width: 100%;
    margin: 0.2rem 0 0.2rem 0;
    position: relative;
}

.tg-input{
    font-size: 0.9rem;
    border-radius: 0.15rem;
    color: #0b204b;
    width: 100%;
    box-sizing: border-box;
    padding: .375rem .45rem;
    font-weight: 500;
    border: 1px solid #ccc;
}

.tg-input:focus {
    outline: none;
}

.tg-input-login{
    font-size: 1.1rem;
    padding: 0.6rem 0.75rem;
    font-weight: 400;
    border: 1px solid #ccc;
    width: 100%;
}

.tg-input-login:focus {
    outline: none;
}

.form-select{
    border-radius: 0.15rem !important;
    font-size: 0.9rem !important;
    border: 1px solid #ccc !important;
    font-weight: 500 !important;
    padding: .375rem 2.25rem .375rem .45rem;
}

.tg-underline {
    border: 1px solid #ccc;
}
  
.tg-underline:focus ~ .tg-border {
    width: 100%;
    transition: 0.4s;
}
  
.tg-underline ~ .tg-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #0b204b;
    transition: 0.4s;
}
  
.tg-underline:focus ~ .tg-border {
    width: 100%;
    transition: 0.4s;
}

// Validation 

.tg-validation-error {
    font-size: 0.7rem;
    color: red;
}

.tg-validation-info {
    margin-top: 0.3rem;
    font-size: 0.7rem;
    color: #0b2848;
}


.tg-error-info {
    margin: 0.6rem 0;
    font-size: 0.8rem;
    color: red;
}

.tg-form-title {
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
}

.p-inputswitch .p-inputswitch-slider{
    background-color: #ced4da !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background-color: var(--secondary-color) !important;
}

.form-check-input:checked{
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
}

.tg-btn{
    padding: .25rem .5rem !important;
    border-radius: .2rem !important;
    font-size: .9rem !important;
}

.tg-submit-btn{
    @extend .tg-btn;
    color: white !important;
    background-color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color) !important;
    &:disabled{
        opacity: 0.6 !important
    }
}

.tg-save-as-draft-btn{
    @extend .tg-btn;
    color: white !important;
    border: 1px solid var(--warning-color) !important;
    background-color: var(--warning-color) !important;
}

.tg-cancel-btn{
    @extend .tg-btn;
    border: 1px solid var(--sub-heading) !important;
    color: var(--sub-heading) !important;
    background-color: white !important;
}

.p-checkbox .p-checkbox-box.p-highlight{
    border-color: var(--secondary-color) !important;
    background: var(--secondary-color) !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    border-color: var(--secondary-color) !important;
}

.currency-dropdown{
    border: 1px solid #dee2e6;
    padding: 0.4rem 0.8rem;
    background-color: var(--selected-fields);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: 0;
}

.currency-input{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.no-right-border{
    border-right: unset !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}