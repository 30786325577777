/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

@import "assets/css/animate.min.css";

@import "assets/css/utils/accordion";
@import "assets/css/utils/inputs";
@import "assets/css/utils/table";
@import "assets/css/utils/mixins/stepper";
@import "assets/css/utils/mixins/utils";
@import "assets/css/utils/mixins/legend";
@import "assets/css/utils/mixins/formControls";
@import "assets/css/utils/mixins/workflow";

:root {
  --tg-primary-color: #0b2848;
  --tg-text-color: #343a40;
  --secondary-color: #4FCC92;
  --warning-color: #E2B83B;
  --sub-heading: #BDBDBD;
  --section-heading-bg: #EAEAEA;
  --selected-fields: #F8F9FA;
  --border: #DEE2E6;
}

body {
  font-family: "Inter", sans-serif !important;
  font-size: 0.8rem;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.badge-status-approved {
  background-color: #e2ffdd;
}

.badge-status-submitted {
  background-color: #f8f6d5;
}

.badge-status-not-submitted {
  background-color: #ffdddd;
}

.badge-status-revoked {
  background-color: #efe6ff;
}

.badge-status-unpaid {
  background-color: #ffdddd;
}

.badge-status-rejected {
  background-color: #ffdddd;
}

.badge-status-paid {
  background-color: #bffaca;
}

.badge-status-default {
  background-color: gray;
}

.view-button {
  background-color: #e3edf8;
  color: rgb(37, 120, 187);
  border: none;
  height: 28px;
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}

.thead {
  background-color: #f5f5f5;
  font-size: 0.8rem;
  color: rgb(106 106 102);
  text-align: left;
}

.tbody {
  vertical-align: middle;
}

.ok-badge {
  width: 1rem;
  height: 1rem;
  // margin: 0.5rem 0;
}

.tg-label-margin {
  margin-bottom: 0.5rem;
}

.tg-validation-error-text {
  height: 0.1rem;
  margin-top: 0.3rem;
  font-size: 0.7rem;
  color: red;
}

.tg-validation-info-text {
  height: 0.1rem;
  margin-top: 0.3rem;
  font-size: 0.7rem;
  color: #0b2848;
}

.tg-form-table tbody>tr>td {
  padding: 0.4rem 1rem !important;
}

.tg-title-holder {
  padding: 0 1rem !important;
}

.tg-primary {
  background-color: #0b2848 !important;
  color: #fafafa !important;
  border: none !important;
}

.tg-primary-button {
  background-color: #0b2848;
  color: #eee;
  font-weight: 300;
  font-size: 1.4rem;
  letter-spacing: 0rem;
  padding: 0.5rem;
  border: none;
  margin: 1rem 0;
  width: 100%;
}

.tg-rotate {
  cursor: pointer;
  display: inline-block;
}

.tg-rotate:hover {
  -webkit-animation: rotate-center 0.5s ease-in-out both;
  animation: rotate-center 0.5s ease-in-out both;
}

.tg-cell-bottom {
  //vertical-align: bottom;
}

.tg-modal-lg {
  max-height: 80vh !important;
  overflow: initial;
  max-width: 70vw !important;
}

.tg-modal-xlg {
  max-height: 90vh !important;
  overflow: initial;
  max-width: 90vw !important;
}

.tg-modal-xxlg {
  overflow: initial;
  max-width: 100vw !important;
  margin: 0 !important;
}

.tg-modal-xxl-body {
  height: 90vh;
  overflow-y: auto;
}

.tg-modal-xl-body {
  height: 85vh;
  overflow-y: auto;
}

.tg-modal-sm-body {
  max-height: 60vh;
  overflow: auto;
}

.tg-modal-body {
  max-height: 85vh !important;
  overflow-y: auto;
}

.tg-modal-md {
  overflow: initial;
  max-width: 50vw !important;
}

.pointer {
  cursor: pointer;
}


/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.rotate-center {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
}

.p-paginator {
  justify-content: end !important;
  padding: 0.5rem 0rem !important;
}

.cluster-title {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
}

.employee-form-title {
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
}

.cluster-bar {
  background-color: #0b2848 !important;
  color: #eee !important;
}

.cluster-bar-inner {
  background-color: #4f1010 !important;
  color: #eee !important;
}

.cluster-button {
  cursor: pointer;
}

.p-menuitem {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-menuitem-icon {
  padding-right: 0.5rem;
}

.tg-status {
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  font-size: 0.8rem;
  letter-spacing: 0.08rem;
  text-align: center;
  border-radius: 0.35rem;
}

.tg-draft {
  background-color: #ece1be;
  min-width: 4rem;
  @extend .tg-status;
}

.tg-submitted {
  background-color: #b6d4fe;
  min-width: 4rem;
  @extend .tg-status;
}

.tg-accepted {
  background-color: #13653f;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-sent-back {
  @extend .tg-status;
  background-color: #ffc107;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-paid {
  background-color: #212d9f;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-posted {
  background-color: #8a5ae1;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-declined {
  @extend .tg-status;
  background-color: #a52834;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-revoked {
  background-color: #5c636a;
  color: #FFFFFF;
  min-width: 4rem;
  @extend .tg-status;
}

.tg-ready-to-convert {
  background-color: #4A8AD7;
  color: #FFFFFF;
  @extend .tg-status;
}

.tg-partially-converted {
  background-color: #4CC1BA;
  color: #FFFFFF;
  @extend .tg-status;
}

.tg-sent-back-status {
  background-color: #D53F3F;
  color: #FFFFFF;
  @extend .tg-status;
}

.tg-in-progress {
  background-color: #F1B162;
  color: #FFFFFF;
  @extend .tg-status;
}

.tg-partially-closed {
  background-color: #8049B7;
  color: #FFFFFF;
  @extend .tg-status;
}

.tg-fully-converted {
  background-color: #49B78D;
  color: #FFFFFF;
  @extend .tg-status;
}

.tg-closed {
  background-color: #DCDCDC;
  color: #000;
  @extend .tg-status;
}

.tg-send-back-popover.tooltip-inner {
  background-color: #ffc107;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.06rem;
  max-width: none;
}

.tg-rejected-popover>.tooltip-inner {
  background-color: #a52834;
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.06rem;
  max-width: none;
}

.tg-rejected-popover>.tooltip-arrow.arrow::before {
  border-top-color: #a52834;
}

.tg-send-back-popover>.tooltip-arrow.arrow::before {
  border-top-color: #ffc107;
}

.tg-form-label {
  display: block;
  font-weight: 500;
  font-size: 0.9rem;
  // letter-spacing: 0.08rem;
}

.tg-form-label-login {
  // margin-bottom: 0.3rem;
  display: block;
  font-weight: 300;
  font-size: 0.9rem;
  // letter-spacing: 0.05rem;
}

.tg-app-version {
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  color: #0b2848;
  opacity: 0.6;
}

.tg-confirmation-message {
  display: block;
  font-weight: 500;
  font-size: 0.85rem;
  letter-spacing: 0.05rem;
  color: #b9112b;
}

.form-label-holder {
  height: 2rem;
}

.title-byline {
  font-size: 0.7rem;
  letter-spacing: 0.1rem;
  color: #FFFFFF;
  margin-top: 0.4rem;
}

.p-tooltip .p-tooltip-text {
  background-color: black !important;
  font-size: 13px;
}

.center-toggle {
  margin-top: 2.5rem;
}

.p-buttonset {
  border: unset;

  .p-button {
    padding: 4px 12px !important;
  }

  .p-button:first-of-type {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .p-button:last-of-type {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.p-button.p-highlight.mandatory {
  background-color: #FF4444 !important;
  border-color: #FF4444 !important;
}

.p-button.p-highlight.optional {
  background-color: #2C73C0 !important;
  border-color: #2C73C0 !important;
}

.p-button.p-highlight.hidden {
  background-color: #0FC925 !important;
  border-color: #0FC925 !important;
}

.tg-amount {
  padding: 0.5rem;
}

.tg-amount-deducted {
  background-color: #F7C5C5;
  color: #ff0000;
  @extend .tg-amount;
}

.tg-amount-added {
  background-color: #9EC5FE;
  color: #044EBB;
  @extend .tg-amount;
}

.tg-amount-transfered {
  background-color: #A0E3C4;
  color: #184731;
  @extend .tg-amount;
}

.preview-button {
  @extend .d-flex, .align-items-center, .gap-2;
  padding: 0.5rem 1rem;
  border: unset;
  border-radius: 0.15rem;
  color: white;
  background-color: var(--secondary-color);
}

.page-wrapper {
  height: 100%;
  margin: 160px;
}

.custom-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: 1rem !important;
  font-size: 0.9rem;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 1rem !important;
  font-size: 0.9rem !important;
}

.p-overlaypanel {
  z-index: 1060 !important;
  box-shadow: 0px 3px 10px #0000004d !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0.75rem !important;
}

input:disabled {
  background-color: #e9ecef !important;
}

.subgroup-heading {
  padding: 0.75rem;
  background-color: var(--section-heading-bg);
  margin-top: 1rem;
  font-weight: 600;
}

.container-login {
  display: flex;
  height: 100vh;
  /* Full height */
}

.left-panel-login {
  width: 38%;
  padding: 20px;
  background: #0b2848;
  color: #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /* Optional: adds a border between panels */
}

.right-panel-login {
  width: 62%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.right-panel-login {
  .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 .2rem #c7d2fe;
    border-color: #50cc92;
  }
}

.tg-secondary-button {
  background-color: #50cc92;
  color: #eee;
  font-weight: 300;
  font-size: 1.4rem;
  letter-spacing: 0;
  padding: 0.5rem;
  border: none;
  margin: 1rem 0;
  width: 100%;
  border-radius: 6px;
}

.login-title {

  color: #0b2848;
  font-weight: 600;
}

.login-subtitle {
  font-size: 14px;
  color: #a9a9a9;
  font-weight: 500;
}

.tg-plain-button {
  background-color: #fff !important;
  border: 1px solid #000;
  border-radius: 7px;
  text-align: center;
  display: block;
}

.align-text-center {
  text-align: center;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
}

.tg-processed {
  background-color: #f9c72e;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-parked {
  background-color: #61E6D8;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-cancelled {
  background-color: #94A3B8;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-hold {
  background-color: #FA8E43;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-status-validation-error {
  background-color: #B32B24;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}

.tg-failed {
  background-color: #e03232;
  color: #FFFFFF;
  min-width: 5rem;
  @extend .tg-status;
}